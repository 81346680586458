<template>
  <div>
    <div class="ddic">
      <h6>管理主辦單位</h6>
      <div class="divs" id="style-3">    
      <a-list item-layout="horizontal"  :data-source="orgdata">
        <a-list-item slot="renderItem" slot-scope="item">
          <a-list-item-meta
            description=""
          >
            <a slot="title" @click="reload(item)" href="#">
             {{item.name}}&ensp;|&ensp;<span :style="{'color': item.state==='N' ? '#cc2626' : '#05a532'}" v-html="rogname(item.type,item.state)" />
            </a> 
            <a-avatar slot="avatar" :style="{'margin-top':'-0.2rem'}" icon="user" :src="item.prourl" />
          </a-list-item-meta>
        </a-list-item>
      </a-list>     
      </div> 
      <div style="text-align: right;">
        <router-link :to="{name:'Org_generate'}">
          <a-button shape="round" type="dashed" icon="bank">(新)建立主辦單位</a-button>
        </router-link>            
      </div> 
    </div>
  </div>
  
</template>
<script>
import * as moment from "moment/moment";
import { mapState,mapActions } from "vuex";

export default {
  name: 'Inforg',    
  data() {
    return {
      data: [],
      items: [],
      orgdata:[],
      selected: '',
      selecitem: '',
    };
  },

  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState("makeorg", ["datas","defaultval"])
  },  
  mounted() {

  },
  methods: {
    ...mapActions('makeorg',['readorg','setorgval']),

    fdateformat(val){
        return moment(val).format("YYYY-MM-DD"); 
    },

    
    rogname(val1,val2){
        let rs =''
        if(val1==="county"){
            rs = "層級 : 縣市政府 &ensp;|&ensp; 狀態 : " 
            rs += val2==='N'? '尚未審核通過':'審核通過'
        }else if(val1==="town"){
            rs = "層級 : 局處/鄉鎮公所  &ensp;|&ensp; 狀態 : " 
            rs += val2==='N'? '尚未審核通過':'審核通過'                
        }else if(val1==="org"){
            rs = "層級 : 據點團體組織  &ensp;|&ensp; 狀態 : " 
            rs += val2==='N'? '尚未審核通過':'審核通過'                
        }else if(val1==="admin"){
            rs = "層級 : 最高管理群組"              
        }
        return rs
    },    
    
    async upload(){
      //let id={id:this.user.data.user.id}
      //this.orgdata = await this.readorg(id);
      this.orgdata = this.datas
    },
    
    reload(rs){
      //console.log(rs)
      //this.setorgval( {name: rs.name,id:rs.id } ) 
      let athority = rs.acts_authority.find(rs => rs.Uid == this.user.data.user.id) 
      this.setorgval( {name: rs.name,id:rs.id ,state:rs.state, permissions:athority } )
    }
  },

  created() {
    //console.log("datas:",this.datas)
    if(this.status.loggedIn){
        this.upload();
    }
    
  }  
};
</script>
<style scoped>
#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px #535151;;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #535151;
}

#style-3{
    text-align: left;
}
.ddic{
    padding:20px;
    height:400px;
    border-radius: 15px;
    text-align: left;
    border-width:1px;
    margin: 13px auto;
    border-style:dotted;
    border-color:#e0dedc;
	transition: all .3s ease;
}
.divs{
    overflow: auto;
    height:330px;
}
</style>