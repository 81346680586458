<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col"><Infomain /></div>
    </div>
    <div class="row">
      <div class="col"><Infobar /></div>
      <div class="col"><Inforg /></div>
    </div>
  </div>        
</template>
<script>
import { mapState } from "vuex";
import Infomain from "./controlbar/Infomain.vue"
import Infobar from "./controlbar/Infobar.vue"  
import Inforg from "./controlbar/Inforg.vue"

export default {
  name: 'Center_infobar',   
  components: {
    Infobar,
    Infomain,
    Inforg
  },
  data() {
    return {
      data: [],
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
  },
  mounted() {

  },
  methods: {
      //router.replace({ name: 'Makact'}).catch(err=>{err})
  },
};
</script>