<template>
  <div>
    <div class="ddic" id="style-3">
      <h6>最新公告</h6>   
      <div class="divs" id="style-3">     
      <a-list item-layout="horizontal" :locale="locale" :data-source="items">
        <a-list-item slot="renderItem" slot-scope="item">
          <a-list-item-meta
            :description="'刊登日期 :'+fdateformat(item.created_at)"
          >
            <a slot="title" href="#">
            <router-link :to="{name:'Info_detail',params:{id:item.id}}">{{ txtmess(item.title,40)  }}</router-link>
            </a> 
            <a-avatar slot="avatar" :style="{'backgroundColor': item.typ=='mess' ? '#87d068' : '#fcb754','margin-top':'0.3rem'}">
              ☰
            </a-avatar>
          </a-list-item-meta>
        </a-list-item>
      </a-list>  
      </div>    
    </div>
  </div>
  
</template>
<script>
import { messService } from '../../../_services'
import * as moment from "moment/moment";
import { mapState,mapMutations } from "vuex";
import 'moment/locale/zh-tw';


const queryData = params => {
  return messService.finditems(params.page,params.pid) 
};  

const updatedata = obj => {
  return  messService.upstatus(obj)
}


export default {
  name: 'Infobar',    
  data() {
    return {
      data: [],
      items: [],
      tmp:[],
      locale:{
        filterConfirm:'確定',
        filterReset: '重置',
        emptyText: '暫時無任何數據'        
      },       
    };
  },

  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState("ws", ["wsState","wsMess"])
  },  
  mounted() {
      if(this.wsState==true){
        this.inids(this.wsMess,1)
      }
  },
  methods: {
    ...mapMutations('ws',['sendMessage']),
    fetch(params = {}) {
      this.loading = true;
      queryData({
        //results: 4,
        ...params,
      }).then(({ data }) => {
          console.log(data)
      });
    },
    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },
    updatestatus(ids){
      let obj ={id: ids}
      updatedata(obj).then(({data}) =>{
        if(data.rs=='done'){
          this.data.find(obj => obj.id == data.id).states = "Y"
        }
      });
    },

    inids(val,step){
        if(step==2){
          this.items =[]
        }
        if(val){
          let {data} = val;
          let itme = {}  
          //console.log(data)
          for(var obj in data) {
            itme =data[obj]
              this.items.push(itme);
            }
          }
          var byDate = this.items.slice(0);
          this.items=byDate.sort((a,b)=> {
              return Date.parse(b.created_at) - Date.parse(a.created_at);
          });
          let tmpdata =[]
          let i=0
          for(var ob1 in this.items){
              if(i<6){
                tmpdata.push(this.items[ob1])  
              }else if (i>6){
                break
              }           
              i++
          }
          this.items = tmpdata 
    },
  
    txtmess(val,i) {
      return val.substr(0,i)+"......"
    },        
  },
  watch: {
      wsMess(val){
          this.inids(val,2)
        },       
    },  
};
</script>
<style scoped>
#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(94, 89, 89, 0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #535151;
}

#style-3{
    text-align: left;
}
.ddic{
    padding:20px;
    text-align: left;
    height:400px;
    border-radius: 15px;
    border-width:1px;
    margin: 13px auto;
    border-style:dotted;
    border-color:#e0dedc;
	transition: all .3s ease;
}
.divs{
    overflow: auto;
    height:350px;
}
</style>